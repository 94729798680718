<template>
    <v-container>
      <v-row class="justify-center pt-2">
        <v-card width="95%" class="primary rounded">
          <v-card-title class="primary" style="color: white">Minhas Turmas </v-card-title>
          <v-data-table
              :headers="headersTurmas"
              :items="turmasList"
              class="elevation-1"
              style="width: 100%"
          >
            <template v-slot:[`item.b2`] = "{ item }">
              <v-chip color="green" dark v-if="item.b2 == 0"> Presente </v-chip>
              <v-chip color="orange" dark v-else-if="item.b2 == 1"> {{ item.b2 }} Falta (s) </v-chip>
              <v-chip color="red" dark v-else> {{ item.b2 }} Falta (s) </v-chip>
            </template>
            <template v-slot:[`item.actions`] = "{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{on, attrs}">
                  <v-btn class="mx-3"
                         v-on="on"
                         v-bind="attrs"
                         color="primary"
                         @click="[
                             aulason = false,
                             materiaison = false,
                             disciplinasLista = [],
                             disciplinason = true,
                             getDisciplina(item.IdTurma)]"
                         icon
                         v-scroll-to="{
                        el: '#Disciplinas',
                        easing: [.6, .80, .30, 1.9],
                        duration: 2000
                    }"
                  >
                    <v-icon dark>
                      mdi-text-box-search
                    </v-icon>
                  </v-btn>
                </template>
                Disciplinas
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
      <br/>
      <SectionOne id="Disciplinas">
        <v-row class="justify-center">
          <v-alert v-if="this.disciplinason==false"
            width="95%"
            class="black--text md-5 mt-5"
            color="amber lighten-2"
            dark
            dense
            border="left"
          >
            <v-icon color="black"> mdi-school-outline </v-icon>
            <span class="pl-2"> Por favor, selecione uma <strong> Turma </strong> para visualizar as <strong>Disciplinas</strong>. </span>
          </v-alert>
          <v-card width="95%" class="primary rounded md-5 mt-5"  v-if="this.disciplinason==true">
            <v-card-title class="primary" style="color: white"> Disciplinas Cursadas 
            <v-icon
            @click=[fecharModalDisciplina()]
            color="white"
            class="ml-auto"
            >
              X
            </v-icon>
            </v-card-title>

            <v-data-table
                :headers="headersDisciplinas"
                :items="disciplinasLista"
                class="elevation-1"
                style="width: 100%"
                items-per-page= "20"
            >
              <template v-slot:[`item.actions`] = "{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{on, attrs}">
                    <v-btn class="mx-3"
                           v-on="on"
                           v-bind="attrs"
                           color="primary"
                           @click="[
                             aulason=true,
                             materiaison=false,
                             frequenciaList = [],
                             getFrequencia(item.IdDisciplina)]"
                           icon
                           v-scroll-to="{
                        el: '#aulas',
                        easing: [.6, .80, .30, 1.9],
                        duration: 2000
                    }"
                    >
                      <v-icon dark>
                        mdi-text-box-search
                      </v-icon>
                    </v-btn>
                  </template>
                  Frequência
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-row>
      </SectionOne>

      <br/>
      <SectionOne id="aulas">
        <v-row class="justify-center">
          <v-alert v-if="this.disciplinason==true && (this.aulason == false && this.materiaison == false)"
              width="95%"
              class="black--text md-5 mt-5"
              color="amber lighten-2"
              dark
              dense
              border="left"
          >
            <v-icon color="black"> mdi-school-outline </v-icon>
            <span class="pl-2"> Por favor, selecione uma <strong> Disciplina </strong> para visualizar suas informações. </span>
          </v-alert>
          <v-card width="95%" class="primary rounded md-5 mt-5"  v-if="this.aulason==true">
            <v-card-title class="primary" style="color: white"> Frequências Registradas 
            <v-icon
            @click=[fecharModalFrequencia()]
            color="white"
            class="ml-auto"
            >
              X
            </v-icon>
            </v-card-title>

            <v-data-table
                :headers="headersFrequencia"
                :items="frequenciaList"
                class="elevation-1"
                style="width: 100%"
            >
              <template v-slot:[`item.DataAula`] = "{ item }">
                {{`${$moment(item.DataAula).format('L')}`}}
              </template>

              <template v-slot:[`item.Faltas`] = "{ item }">
                <v-chip color="green" dark v-if="item.Faltas == 0"> Presente </v-chip>
                <v-chip color="orange" dark v-else-if="item.Faltas != 0 && item.Faltas != item.aulas"> {{ item.Faltas }} Falta (s) </v-chip>
                <v-chip color="red" dark v-else> {{ item.Faltas }} Falta (s) </v-chip>
              </template>
            </v-data-table>
          </v-card>
        </v-row>
      </SectionOne>
      <br/>
      <sectionOne id="materiais">
        <v-row class="justify-center">
          <v-card width="95%" class="primary rounded" v-if="this.materiaison==true">
            <v-card-title class="primary" style="color: white"> Materiais </v-card-title>
              <v-data-table
                  :headers="headersMateriais"
                  :items="desserts2"
                  class="elevation-1"
                  style="width: 100%"
              >
                <template v-slot:[`item.actions`] = "{  }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{on, attrs}">
                      <v-btn
                          color="primary"
                          v-on="on"
                          v-bind="attrs"
                          icon
                          dark
                          small
                      >
                        <v-icon dark>
                          mdi-download
                        </v-icon>
                      </v-btn>
                    </template>
                    Download
                  </v-tooltip>
                </template>
              </v-data-table>
          </v-card>
        </v-row>
      </sectionOne>
      <v-btn
          v-scroll="onScroll"
          v-show="fab"
          class="mx-2"
          fab
          dark
          fixed
          bottom
          small
          right
          color="primary"
          @click="toTop"
      >
        <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
    </v-container>
</template>

<script>
export default {
  name: "AlunoFrequencia",

  data: () => ({
    turmasList:[],
    frequenciaList:[],
    disciplinasLista:[],
    TurmaSelecionada:null,
    AulaSelecionada:null,
    aulason: false,
    materiaison: false,
    disciplinason: false,
    fab: false,
    headersTurmas: [
      {text: 'Ano Letivo', value: 'AnoLetivo', show: true, align: 'left', width: '11%'},
      {text: 'Turma', value: 'TurmaDescricao', show: true, width: '25%', align: 'left'},
      {text: 'Escola', value: 'Unidade', show: true, width: '46%',align: 'left'},
      {text: 'Ações', value: 'actions', show:true, width:'18%', align: 'center'}
    ],
    headersDisciplinas: [
      {text: 'Disciplina', value: 'Disciplina', show:true, width: '36%'},
      {text: 'Docente', value: 'Docente', show:true, width: '46%'},
      {text: 'Ações', value: 'actions', show: true, align: 'center', width: '18%'},
    ],
    headersFrequencia: [
      {text: 'Data da Aula', value: 'DataAula', show: true, align: 'center', width: '50%'},
      {text: 'Situação', value: 'Faltas', align:'center',show: true, width: '50%'},
    ],

  }),
  methods:{
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop () {
      this.$vuetify.goTo(0)
    },
    getTurmas(){
      this.IdUnidade = parseInt(sessionStorage.getItem('unidade'))
      this.axios.get('turma/aluno').then((res) => {
        this.turmasList = res.data.map(turma => {
          return {
            IdTurma: turma.IdTurma,
            TurmaDescricao: turma.Serie+' - '+turma.TurmaDescricao,
            AnoLetivo: turma.AnoLetivo,
            Unidade: turma.Unidade,
            Sigla: turma.Sigla,
            IdTurmaDisciplina: turma.IdTurmaDisciplina,
          }
        })
      })
    },
    getDisciplina(Idturma){
      this.axios.get('turma/disciplinas', {
        params: {
          IdTurma: Idturma,
        }
      }).then((res) => {
        this.disciplinasLista = res.data.map(disciplina => {
          return {
            Disciplina: disciplina.Disciplina,
            Docente: disciplina.Docente,
            IdDisciplina:disciplina.IdTurmaDisciplina

          }
        })
      })
    },
    getFrequencia(IdTurmaDisciplina) {
      this.axios.get('aluno/turma/frequencia', {
        params: {
          IdDisciplina: IdTurmaDisciplina,
        }
      }).then((res) => {
        this.frequenciaList = res.data.map(frequencia => {
            return {
              DataAula: frequencia.DataAula,
              aulas: frequencia.aulas,
              Faltas: frequencia.aulas - frequencia.presencas
          }
        })
      })
    },
  
   fecharModalDisciplina(){
       this.disciplinason = false,
       this.aulason = false
    },
  fecharModalFrequencia(){
       this.aulason = false
    },
  },
  mounted(){
    this.getTurmas();
  }
}
</script>

<style scoped>

</style>