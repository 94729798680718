var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-fluid",staticStyle:{"overflow-y":"auto"},attrs:{"max-height":"430"}},[_c('br'),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"shaped":"","elevation":"1"}},[_c('h4',{staticClass:"primary--text font-weight-bold pt-4 mt-0",staticStyle:{"text-align":"center !important"}},[_vm._v(" PREFEITURA MUNICIPAL DE MACAÍBA - INDICADORES ")]),_c('h5',{staticClass:"accent--text font-weight-bold pb-4 mb-0",staticStyle:{"text-align":"center"}},[_vm._v(" PAINEL DE INFORMAÇÕES DA EDUCAÇÃO ")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mdAndDown ? 12 : 6}},[_c('div',{staticClass:"col-xl-6 col-md-12"},[_c('filtroAno',{on:{"messageFromChild":_vm.recall}})],1)])],1),_c('v-row',[_c('card-indicadores',{attrs:{"type":true,"color":'primary',"icon":'school',"label":'Quantidade de escolas',"data":_vm.qtdEscolas},nativeOn:{"click":function($event){return _vm.handleNextCards('escolas')}}}),_c('card-indicadores',{attrs:{"type":true,"color":'primary',"icon":'users',"label":'Quantidade de estudantes',"data":_vm.qtdAlunos},nativeOn:{"click":function($event){return _vm.handleNextCards('alunos')}}}),_c('card-indicadores',{attrs:{"type":true,"color":'primary',"icon":'chalkboard-teacher',"label":'Quantidade de professores',"data":_vm.qtdProfessores},nativeOn:{"click":function($event){return _vm.handleNextCards('professores')}}}),_c('card-indicadores',{attrs:{"type":true,"color":'primary',"icon":'graduation-cap',"label":'Quantidade de turmas',"data":_vm.qtdTurmas},nativeOn:{"click":function($event){return _vm.handleNextCards('turmas')}}})],1),_c('v-row',[_c('div',{class:_vm.$vuetify.breakpoint.mdAndDown
          ? 'col-xl-12 col-md-12 mb-2'
          : 'col-xl-6 col-lg-6 mb-2'},[_c('div',{staticClass:"card shadow mb-3"},[_c('div',{staticClass:"card-header py-3 d-flex align-items-center justify-content-between"},[_c('h6',{staticClass:"m-0 font-weight-bold text-primary"},[_vm._v(" Calendário bimestral ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(" Calendário letivo de aulas, por bimestre ")])])],1),_c('div',{staticClass:"card-body align-items-center justify-content-between",attrs:{"id":"Calendario"}},[_c('calendario',{key:_vm.calendario,attrs:{"idAno":_vm.idAno}})],1)])]),_c('div',{class:_vm.$vuetify.breakpoint.mdAndDown
          ? 'col-xl-12 col-md-12'
          : 'col-xl-6 col-lg-6 mb-2'},[_c('div',{staticClass:"card shadow"},[_c('div',{staticClass:"card-header py-3 d-flex align-items-center justify-content-between"},[_c('h6',{staticClass:"m-0 font-weight-bold text-primary"},[_vm._v(" Quantitativo de escolas por zona rural/urbana ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(" Veja o quantitativo de escolas por zona: rural e urbana ")])])],1),_c('div',{staticClass:"card-body",attrs:{"id":"Pizza"}},[_c('card-pie-educacao',{key:_vm.showPie,attrs:{"data":_vm.escolas}})],1)])])]),_c('v-row',[_c('div',{class:_vm.$vuetify.breakpoint.mdAndDown
          ? 'col-xl-12 col-md-12 mb-2'
          : 'col-xl-6 col-lg-6 mb-2'},[_c('div',{staticClass:"card shadow mb-3"},[_c('div',{staticClass:"card-header py-3 d-flex align-items-center justify-content-between"},[_c('h6',{staticClass:"m-0 font-weight-bold text-primary"},[_vm._v(" Resumo dos estudantes que utilizam transporte escolar ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(" Resumo dos estudantes que utilizam transporte escolar")])])],1),_c('div',{attrs:{"id":"chart1"}},[_c('apexchart',{key:_vm.showTransporteEscolar,attrs:{"type":"bar","height":"350","options":_vm.chartOptionsTransporteEscolar,"series":_vm.seriesTransportes}})],1)])]),_c('div',{class:_vm.$vuetify.breakpoint.mdAndDown
        ? 'col-xl-12 col-md-12'
        : 'col-xl-6 col-lg-6 mb-2'},[_c('div',{staticClass:"card shadow"},[_c('div',{staticClass:"card-header py-3 d-flex align-items-center justify-content-between"},[_c('h6',{staticClass:"m-0 font-weight-bold text-primary"},[_vm._v(" Escolas com estudantes que utilizam transporte escolar ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(" Escolas com estudantes que utilizam transporte escolar ")])])],1),_c('div',{attrs:{"id":"chart2"}},[_c('v-data-table',{attrs:{"height":"305","items":_vm.unidadesTransporteEscolarList,"headers":_vm.headersUnidadesAlunosEspeciais,"items-per-page":5}})],1)])])]),_c('v-row',[_c('div',{class:_vm.$vuetify.breakpoint.mdAndDown
          ? 'col-xl-12 col-md-12 mb-2'
          : 'col-xl-6 col-lg-6 mb-2'},[_c('div',{staticClass:"card shadow mb-3"},[_c('div',{staticClass:"card-header py-3 d-flex align-items-center justify-content-between"},[_c('h6',{staticClass:"m-0 font-weight-bold text-primary"},[_vm._v(" Resumo dos(as) estudantes com deficiência, transtorno do espectro autista, altas habilidades: ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(" Resumo dos(as) estudantes com deficiência, transtorno do espectro autista, altas habilidades: ")])])],1),_c('div',{attrs:{"id":"chart1"}},[_c('apexchart',{key:_vm.showAlunosDeficientes,attrs:{"type":"bar","height":"350","options":_vm.chartOptionsAlunos,"series":_vm.seriesAlunos}})],1)])]),_c('div',{class:_vm.$vuetify.breakpoint.mdAndDown
        ? 'col-xl-12 col-md-12'
        : 'col-xl-6 col-lg-6 mb-2'},[_c('div',{staticClass:"card shadow"},[_c('div',{staticClass:"card-header py-3 d-flex align-items-center justify-content-between"},[_c('h6',{staticClass:"m-0 font-weight-bold text-primary"},[_vm._v(" Escolas com estudantes com deficiência, transtorno do espectro autista, altas habilidades ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(" Escolas com estudantes com deficiência, transtorno do espectro autista, altas habilidades ")])])],1),_c('div',{attrs:{"id":"chart2"}},[_c('v-data-table',{attrs:{"height":"305","items":_vm.unidadesAlunosEspeciaisList,"headers":_vm.headersUnidadesAlunosEspeciais,"items-per-page":5}})],1)])])]),_c('v-row',[_c('div',{class:_vm.$vuetify.breakpoint.mdAndDown
        ? 'col-xl-12 col-md-12 mb-2'
        : 'col-xl-6 col-lg-6 mb-2'},[_c('div',{staticClass:"card shadow mb-3"},[_c('div',{staticClass:"card-header py-3 d-flex align-items-center justify-content-between"},[_c('h6',{staticClass:"m-0 font-weight-bold text-primary"},[_vm._v(" Resumo dos(as) estudantes com outra condição de necessidade específica: ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(" Estudantes com outra condição de necessidade específica ")])])],1),_c('div',{attrs:{"id":"chart1"}},[_c('apexchart',{key:_vm.showAlunosDeficientesEspecifica,attrs:{"type":"bar","height":"350","options":_vm.chartOptionsAlunosEspecifica,"series":_vm.seriesAlunosEspecifica}})],1)])]),_c('div',{class:_vm.$vuetify.breakpoint.mdAndDown
          ? 'col-xl-12 col-md-12'
          : 'col-xl-6 col-lg-6 mb-2'},[_c('div',{staticClass:"card shadow"},[_c('div',{staticClass:"card-header py-3 d-flex align-items-center justify-content-between"},[_c('h6',{staticClass:"m-0 font-weight-bold text-primary"},[_vm._v(" Escolas com estudantes com outra condição de necessidade específica ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(" Escolas com estudantes com outra condição de necessidade específica ")])])],1),_c('div',{attrs:{"id":"chart2"}},[_c('v-data-table',{attrs:{"height":"305","items":_vm.unidadesAlunosOutrosList,"headers":_vm.headersUnidadesAlunosEspeciais,"items-per-page":5}})],1)])])]),_c('v-row',[_c('div',{class:_vm.$vuetify.breakpoint.mdAndDown
          ? 'col-xl-12 col-md-12 mb-2'
          : 'col-xl-6 col-lg-6 mb-2'},[_c('div',{staticClass:"card shadow mb-3"},[_c('div',{staticClass:"card-header py-3 d-flex align-items-center justify-content-between"},[_c('h6',{staticClass:"m-0 font-weight-bold text-primary"},[_vm._v(" Resumo dos estudantes que necessitam de recursos de sala de aula ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(" Resumo dos estudantes que necessitam de recursos de sala de aula")])])],1),_c('div',{attrs:{"id":"chart1"}},[_c('apexchart',{key:_vm.showAlunosRecursosSalaAula,attrs:{"type":"bar","height":"350","options":_vm.alunosRecursosSalaAula,"series":_vm.seriesAlunosRecursosSalaAula}})],1)])]),_c('div',{class:_vm.$vuetify.breakpoint.mdAndDown
        ? 'col-xl-12 col-md-12'
        : 'col-xl-6 col-lg-6 mb-2'},[_c('div',{staticClass:"card shadow"},[_c('div',{staticClass:"card-header py-3 d-flex align-items-center justify-content-between"},[_c('h6',{staticClass:"m-0 font-weight-bold text-primary"},[_vm._v(" Escolas com estudantes que necessitam de professor auxiliar ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(" Escolas com estudantes que necessitam de professor auxiliar ")])])],1),_c('div',{attrs:{"id":"chart2"}},[_c('v-data-table',{attrs:{"height":"305","items":_vm.unidadesProfessorAuxiliar,"headers":_vm.headersUnidadesAlunosEspeciais,"items-per-page":5}})],1)])])]),_c('v-row',[_c('div',{class:_vm.$vuetify.breakpoint.mdAndDown
          ? 'col-xl-12 col-md-12 mb-2'
          : 'col-xl-6 col-lg-6 mb-2'},[_c('div',{staticClass:"card shadow mb-3"},[_c('div',{staticClass:"card-header py-3 d-flex align-items-center justify-content-between"},[_c('h6',{staticClass:"m-0 font-weight-bold text-primary"},[_vm._v(" Comparativo anual de matrículas ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(" Veja o comparativo anual de matriculas dos últimos anos ")])])],1),_c('div',{staticClass:"card-body py-3 align-items-center justify-content-between"},[_c('quantitativoMatriculados')],1)])]),_c('div',{class:_vm.$vuetify.breakpoint.mdAndDown
          ? 'col-xl-12 col-md-12 mb-2'
          : 'col-xl-6 col-lg-6 mb-2'},[_c('div',{staticClass:"card shadow mb-3"},[_c('div',{staticClass:"card-header py-3 d-flex align-items-center justify-content-between"},[_c('h6',{staticClass:"m-0 font-weight-bold text-primary"},[_vm._v(" Quantitativo por situação acadêmica ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(" Veja o quantitativo de estudantes por situação acadêmica, após a finalização do ano letivo. ")])])],1),_c('div',{staticClass:"card-body align-items-center justify-content-between"},[_c('quantitativo',{key:_vm.quantitativo,attrs:{"idAno":_vm.idAno}})],1)])])]),_c('v-row',[_c('div',{class:_vm.$vuetify.breakpoint.mdAndDown
          ? 'col-xl-12 col-md-12 mb-2'
          : 'col-xl-6 col-lg-6 mb-2'},[_c('div',{staticClass:"card shadow mb-4"},[_c('div',{staticClass:"card-header py-0 d-flex flex-row align-items-center justify-content-between"},[_c('h6',{staticClass:"m-0 font-weight-bold text-primary"},[_vm._v("Zona urbana")]),_c('div',{staticClass:"col-xl-6 col-lg-6 d-flex"},[_c('filtroEtapa',{staticClass:"mr-5",on:{"messageFromChild":_vm.setEtapaUrbana}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(" Veja o quantitativo de alunos masculino e femino na zona urbana. ")])])],1)]),_c('div',{staticClass:"card-body"},[_c('graficoUrbana',{key:_vm.etapaUrbana,attrs:{"idEtapa":_vm.idEtapaUrbana,"idAno":_vm.idAno,"idZona":_vm.idZonaUrbana}})],1)])]),_c('div',{class:_vm.$vuetify.breakpoint.mdAndDown
          ? 'col-xl-12 col-md-12 mb-2'
          : 'col-xl-6 col-lg-6 mb-2'},[_c('div',{staticClass:"card shadow mb-4"},[_c('div',{staticClass:"card-header py-0 d-flex flex-row align-items-center justify-content-between"},[_c('h6',{staticClass:"m-0 font-weight-bold text-primary"},[_vm._v("Zona rural")]),_c('div',{staticClass:"col-xl-6 col-lg-6 d-flex"},[_c('filtroEtapa',{staticClass:"mr-5",on:{"messageFromChild":_vm.setEtapaRural}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(" Veja o quantitativo de alunos masculino e femino na zona rural. ")])])],1)]),_c('div',{staticClass:"card-body"},[_c('graficoRural',{key:_vm.etapaRural,attrs:{"idEtapa":_vm.idEtapaRural,"idAno":_vm.idAno,"idZona":_vm.idZonaRural}})],1)])])]),_c('v-row',[_c('div',{staticClass:"col-xl-12 col-md-12 mb-2"},[_c('div',{staticClass:"card shadow mb-4"},[_c('div',{staticClass:"card-header py-3 d-flex flex-row align-items-center justify-content-between"},[_c('h6',{staticClass:"m-0 font-weight-bold text-primary"},[_vm._v("Professores por turno")])]),_c('div',{staticClass:"card-body"},[_c('v-text-field',{staticClass:"ml-3 mr-3",attrs:{"prepend-icon":"mdi-feature-search-outline","label":"Pesquisar","clearable":""},model:{value:(_vm.searchProfessoresTurno),callback:function ($$v) {_vm.searchProfessoresTurno=$$v},expression:"searchProfessoresTurno"}}),_c('v-data-table',{attrs:{"search":_vm.searchProfessoresTurno,"headers":_vm.headersProfessoresTurno,"items":_vm.professoresTurnoList,"items-per-page":5}})],1)])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }