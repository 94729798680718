<template>
    <div id="chartId">
        <apexchart type="bar" :height="$vuetify.breakpoint.lgAndUp ? '250' : '360'" :options="options" :series="series"></apexchart>
    </div>
</template>
<script>
import pt from 'apexcharts/dist/locales/pt-br.json'
export default {
    props: {
        idAno: Number,
        idUnidade: Number
    },
    data() {
        return {
            series: [
                {
                    name: 'Aprovados',
                    data: []
                },
                {
                    name: 'Reprovados',
                    data: []
                },
                {
                    name: 'Dependentes',
                    data: []
                },
                {
                    name: 'Evadidos',
                    data: []
                },
                {
                    name: 'Outros',
                    data: []
                }
            ],
            options: {
                chart: {
                    locales: [pt],
                    defaultLocale: 'pt-br',
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    stackType: '100%',
                    toolbar: {
                        show: true
                    },
                    zoom: {
                        enabled: true
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }],
                plotOptions: {
                    bar: {
                        borderRadius: 8,
                        horizontal: false,
                    },
                },
                xaxis: {
                    type: 'string',
                    categories: ['CRECHE', 'PRE-ESCOLA', 'ANOS INICIAIS', 'ANOS FINAIS'],
                },
                legend: {
                    position: 'right',
                    offsetX: 0,
                    offsetY: 50
                },
                fill: {
                    opacity: 1
                },
                noData: {
                    align: 'center',
                    text: 'Nenhum dado disponível até o momento',
                    verticalAlign: 'middle'
                },
            },
        }
    },
    methods: {
        getAlunos(){
            this.axios.get('turma/situacao', {
                params: {
                    ano: this.$props.idAno
                }
            }).then(res => {
                
                this.$emit('isEmpty', res.data)

                this.options = {
                    xaxis: {
                        categories: ['CRECHE', 'PRE-ESCOLA', 'ANOS INICIAIS', 'ANOS FINAIS']
                    }
                }

                for(let i = 0; i < 5; i++){
                    for(let j = 0; j < 4; j++){
                        if(i == 0) this.series[i].data.push(res.data[j].Aprovado)
                        if(i == 1) this.series[i].data.push(res.data[j].Reprovado)
                        if(i == 2) this.series[i].data.push(res.data[j].Dependencia)
                        if(i == 3) this.series[i].data.push(res.data[j].Evadido)
                        if(i == 4) this.series[i].data.push(res.data[j].Outros)
                    }
                }
            })
        }
    },
    mounted() {
        this.getAlunos()
    }
}
</script>
