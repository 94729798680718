<template>
    <div>
        <v-row>
            <v-col
                :key="index" v-for="index in CalendarioAnual"
                cols="6"
                style="margin-top: 18px;"
            >
                <v-row>
                    <v-avatar
                        style="margin-top: 17px;"
                        size="22"
                        :color="verificarBimestre(index.periodo[0], index.periodo[1]).color"
                        rounded
                        class="elevation-1"
                    >
                    </v-avatar>
                    <div class="ms-3">
                        <p class="text-sm mb-1">
                            {{ index.name }}
                        </p>
                        <div class="text-sm font-weight-semibold">
                        {{ $moment($moment(index.periodo[0])).format('L') }}
                            -
                        {{ $moment($moment(index.periodo[1])).format('L') }}
                        </div>
                    </div>
                </v-row>
            </v-col>
        </v-row>
        <v-row class="justify-center">
            <v-col
                :key="index" v-for="index in legenda"
                cols="0"
                class="d-flex align-center pl-8"
            >
                <v-avatar
                    size="15"
                    :color="resolveStatisticsIconVariation(index).color"
                    rounded
                    class="elevation-1"
                >
                    <v-icon
                    dark
                    color="white"
                    size="15"
                    >
                    
                    </v-icon>
                </v-avatar>
                <div class="ms-3">
                    <p class="text-sm mb-0">
                        {{ index }}
                    </p>
                </div>
            </v-col>
        </v-row>

    </div>
</template>

<script>
import { mdiAccountClock, mdiAccountReactivateOutline, mdiAccountArrowRightOutline, mdiAccountSearchOutline, mdiAccountCheckOutline } from '@mdi/js'
import moment from 'moment'

export default {
    name: 'Calendario',
    props: {
        idAno: Number
    },
    data() {
        return {
            icons: {
                mdiAccountClock,
                mdiAccountArrowRightOutline,
                mdiAccountReactivateOutline,
                mdiAccountSearchOutline,
                mdiAccountCheckOutline
            },
            legenda: ['Vigente', 'Em aberto', 'Finalizado'],
            CalendarioAnual: [
                {
                    name: '',
                    periodo: [],
                },
                {
                    name: '',
                    periodo: [],
                },
                {
                    name: '',
                    periodo: [],
                },
                {
                    name: '',
                    periodo: [],
                },
            ],
        }
    },
    methods: {
        resolveStatisticsIconVariation(data) {
            if (data === 'Vigente') return { icon: mdiAccountClock, color: '#FFCC00' }
            if (data === 'Em aberto') return { icon: mdiAccountArrowRightOutline, color: '#00e396' }
            if (data === 'Finalizado') return { icon: mdiAccountSearchOutline, color: '#E30000' }

            return { icon: mdiAccountCheckOutline, color: '#FFFFFF' }
        },

        verificarBimestre(dataInicio, dataFim){
            if(typeof dataInicio && typeof dataFim === "undefined"){
                return { icon: mdiAccountClock, color: '#00e396' }
            }
            if(moment().isSameOrAfter(dataInicio) && moment().isSameOrBefore(dataFim)) return { icon: mdiAccountClock, color: '#FFCC00' }
            if(moment().isSameOrBefore(dataInicio)) return { icon: mdiAccountArrowRightOutline, color: '#00e396' }
            if(moment().isSameOrAfter(dataFim)) return { icon: mdiAccountSearchOutline, color: '#E30000' }

            return { color: '#FFFFFF' }
        },
        groupBy(array, key) {
			return array.reduce((result, currentValue) => {(
				result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
				return result;
			}, {});
		},
        getCalendario(){
            this.axios.get('turma/calendario', {
                params: {
                    AnoLetivo: this.$props.idAno
                }
            }).then(res => {
                const groupedDivision = this.groupBy(res.data, 'Divisao')
                const groupedDateStart = this.groupBy(res.data, 'DataInicial')
                const groupedDateEnd = this.groupBy(res.data, 'DataFinal')
                const division = Object.keys(groupedDivision)
                const inicio = Object.keys(groupedDateStart)
                const fim = Object.keys(groupedDateEnd)
                for(let key in division){
                    this.CalendarioAnual[key].name = division[key]
                    this.CalendarioAnual[key].periodo.push(inicio[key], fim[key])
                }
            })
        },
    },
    mounted(){
        this.getCalendario()
    }
}
</script>