<template>
    <div id="chartPercentual">
        <apexchart type="bar" height="350" :options="chartOptionsOcupacao" :series="seriesOcupacao"></apexchart>
    </div>
</template>

<script>

import pt from 'apexcharts/dist/locales/pt-br.json'

export default {
    props: {
        dados: Array
    },
    data() {
        return { 
            seriesOcupacao: [{
                name: 'Matriculados',
                data: []
            }, {
                name: 'Turmas',
                data: []
            }],
            chartOptionsOcupacao: {
                chart: {
                    locales: [pt],
                    defaultLocale: "pt-br",
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    toolbar: {
                        show: true
                    },
                    zoom: {
                        enabled: true
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }],
                plotOptions: {
                    bar: {
                        borderRadius: 8,
                        horizontal: false,
                    },
                },
                xaxis: {
                    type: 'string',
                    categories: [],
                },
                legend: {
                    position: 'right',
                    offsetY: 40
                },
                fill: {
                    opacity: 1
                },
                noData: {
                    align: "center",
                    text: "Nenhum dado disponível até o momento",
                    verticalAlign: "middle"
                },
            },
        }       
    },
    methods: {
        getOcupacaoUnidade(){
            this.resetSeries()
                            
            for(let i = 0; i < this.$props.dados.length; i++){
                this.seriesOcupacao[0].data.push(this.$props.dados[i].QtdAlunos)
                this.seriesOcupacao[1].data.push(this.$props.dados[i].QtdTurmas)
                this.chartOptionsOcupacao.xaxis.categories.push(this.$props.dados[i].Serie)
            }
        },
        resetSeries(){
            this.seriesOcupacao = [{...this.seriesOcupacao[0], ...{
                    data: []
                    }
                },{...this.seriesOcupacao[1], ...{
                    data: []
                    }
                }]
            this.chartOptionsOcupacao = {
                xaxis: {
                    categories: []
                }
            }
        }
    },
    mounted() {
        this.getOcupacaoUnidade()
    }
}
</script>
