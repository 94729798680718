<template>
  <div class="page">
    <br />
    <div class="container-fluid">
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 class="h3 mb-0 text-gray-800">Calendário Acadêmico</h1>
      </div>
      <v-col :cols="2">
        <v-select
          :items="data"
          v-model="ano"
          item-text="ano"
          item-value="ano"
          label="Filtrar pelo Ano"
          @change="getCalendario()"
          hide-details
          outlined
          dense
        ></v-select>
      </v-col>
      <v-data-table
        :headers="headersCalendario"
        :items="dados"
        hide-default-footer
        class="elevation-1"
        :loading="loading"
        loading-text="Carregando..."
      >
        <template v-slot:[`item.DataInicial`]="{ item }">
          <span>{{ $moment(item.DataInicial).format("L") }}</span>
        </template>
        <template v-slot:[`item.DataFinal`]="{ item }">
          <span>{{ $moment(item.DataFinal).format("L") }}</span>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dados: [],
      dataInicial: [],
      dataFinal: [],
      ano: new Date().getFullYear(),
      data: [],
      loading: false,
      search: null,
      headersCalendario: [
        { text: "Bimestre", value: "Divisao", width: "33%" },
        { text: "Data Inicial", value: "DataInicial", width: "33%" },
        { text: "Data Final", value: "DataFinal", width: "1%" },
      ],
    };
  },
  methods: {
    getCalendario() {
      this.axios
        .get("/turma/calendario", { params: { AnoLetivo: this.ano } })
        .then((res) => {
          this.dados = res.data;
        });
      this.loading = false;
    },
    getAnos() {
      let max = new Date().getFullYear();
      let min = max - 7;
      let anos = [];

      for (let i = max; i >= min; i--) {
        anos.push({
          ano: i,
        });
      }
      this.data = anos;
    },
  },
  mounted() {
    this.getAnos();
    this.getCalendario();
  },
};
</script>