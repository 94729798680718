<template>
    <v-select
        :items="seriesList"
        v-model="idSerie"
        item-text="Serie"
        item-value="IdSerie"
        label="Filtrar pela ano"
        @change="sendMessageToParent($event)"
        hide-details
        outlined
        dense
    ></v-select>
</template>

<script>
export default {
    data() {
        return {
            seriesList: [],
            idSerie: 26
        }
    },
    methods: {
        sendMessageToParent(value){
            this.$emit('messageFromChild', value)
        }
    },
    mounted(){
        this.axios.get('turma/series').then(res => {
            this.seriesList = res.data
        })
    }
}
</script>
